import * as React from "react";
import { cn } from "./lib/utils"; 
import { useTheme } from "next-themes";  

const Textarea = React.forwardRef(({ className, ...props }, ref) => {
  const { theme } = useTheme();  

  const placeholderColor = theme === "dark" ? "#2A2A2A" : "#FFFFFF";

  return (
    <>
      <textarea
        className={cn(
          "merlinExt-custom-scrollbar align-middle  ms-2 scrollbar-w-1.5 z-20 max-h-[40px] min-h-[40px] w-full resize-none rounded-md bg-transparent p-2 text-base outline-none disabled:cursor-not-allowed",
          className
        )}
        ref={ref}
        {...props}
        style={{ color: placeholderColor }}  
        autoFocus
      />
      <style jsx>{`
        .merlinExt-custom-scrollbar::placeholder {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 500;
          // line-height: 18px;
          color: ${placeholderColor};  
          margin-left:5px;
        }
      `}</style>
    </>
  );
});

Textarea.displayName = "Textarea";

export { Textarea };

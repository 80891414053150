import { useState } from 'react';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import { Textarea } from '../../ui/textarea';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "../../ui/dialog";
import { Send, Image } from 'lucide-react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import './index.scss';
import { useTheme } from 'next-themes';
import UploadFiles from '../FileUploader';
import darkBookMark from '../../Assets/darkBookMark.png';
import lightBookMark from '../../Assets/lightBookMark.png';
import lightImageIcon from '../../Assets/lightImageIcon.png';
import darkImageIcon from '../../Assets/darkImageIcon.png';

const InputBase = () => {
    const { theme } = useTheme();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleIconClick = () => {
        setIsDialogOpen(true);  // Open the dialog
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);  // Close the dialog
    };

    return (
        <div
            className="build-up-chat-screen-footer"
            style={{
                backgroundColor: theme === 'dark' ? '#121212' : '#D8D8D8',
            }}
        >
            <div className={`${theme === 'dark' ? 'build-up-dark-input-section' : 'build-up-light-input-section'} build-up-input-section`}>
                <img
                    src={theme == "dark" ? darkBookMark : lightBookMark}
                    style={{ height: "33px", width: "33px", cursor: "pointer" }} />

                <div className={`${theme === 'dark' ? 'build-up-dark-input-container' : 'build-up-light-input-container'} build-up-input-container  flex-1 `}>
                    <Textarea placeholder="Start Chatting Now" className="build-up-merlinExt-custom-scrollbar" />
                    <AttachFileIcon
                        size={25}
                        onClick={handleIconClick}
                        className="me-2 "
                        />&nbsp;
                    {/* <Image
                        size={25}
                        onClick={handleIconClick}
                        className='me-2'
                    /> */}
                    <img
                        onClick={handleIconClick}
                        className='me-2'
                        src={theme == "dark" ? darkImageIcon : lightImageIcon}
                        style={{ height: "22.86px", width: "22.86px", cursor: "pointer", marginRight:"10px" }} />
                </div>

                <Send
                    style={{
                        color: theme === 'dark' ? '#f1f894' : '#000000',
                    }}
                    size={25}
                />
            </div>
            <div className='build-up-footer-text'>
            <p style={{fontSize:"14px"}} >Copyright 2024 BuildUp Foundation</p>
            </div>

            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen} >
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Uploaded File</DialogTitle>
                        <DialogDescription>
                            <UploadFiles />
                        </DialogDescription>
                    </DialogHeader>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default InputBase;

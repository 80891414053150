import { Button } from "../../ui/button";
import { Gem } from 'lucide-react';
import { useTheme } from 'next-themes';

const UpgradBtn = () => {
    const {theme}=useTheme();
    return (
        <>
            <Button className="build-up-upgrade-btn" style={{
                        color: theme === "dark" ? '#FFFFFF' : '#000000' 
                        }} >
                <Gem size={23} />&nbsp; UPGRADE PLAN
            </Button></>
    )
}

export default UpgradBtn
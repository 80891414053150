import InputBase from '../Common/InputBase';
import { useTheme } from 'next-themes';
import { Button } from "../../ui/button";
import { Copy, RotateCw, Redo, Download } from 'lucide-react';
import { Toaster, toast } from 'sonner';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ImageIcon from '@mui/icons-material/Image';
import pdfIcon from '../../Assets/pdf-icon (1).png';
import pptIcon from '../../Assets/ppt-icon.png';
import csvIcon from '../../Assets/csv-icon.png';
import docs from '../../Assets/docx-icon.png';
import nightShare from '../../Assets/nightShare.png';
import lightShare from '../../Assets/lightShare.png';
import './index.scss';

const getYouTubeEmbedUrl = (text) => {
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = text.match(youtubeRegex);
    return match ? `https://www.youtube.com/embed/${match[1]}` : null;
};
const getFileTypeIcon = (type) => {
    switch (type) {
        case 'application/pdf':
            return <img src={pdfIcon} alt="PDF Icon" width={20} height={20} />;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return <img src={docs} alt="Word Icon" width={20} height={20} />;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return <img src={csvIcon} alt="Excel Icon" width={20} height={20} />;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return <img src={pptIcon} alt="PPT Icon" width={20} height={20} />;
        case 'image/png':
        case 'image/jpeg':
            return <ImageIcon size={20} />;
        case 'application/octet-stream':
            return <DocumentScannerIcon size={20} />;
        default:
            return null;
    }
};
const Index = () => {
    const messages = [
        { text: "Hello! Can u help me ?", sender: "user" },
        { text: "Hello! How can I assist you today?", sender: "bot" },
        { text: "I'm working on a new project", sender: "user" },
        { text: "Sure! What kind of project are you working on?", sender: "bot" },
        { text: "It's a web application for managing tasks.", sender: "user" },
        { text: "### Training Races on    Armenian Camp, nestled along the serene shores of Sevan Lake, is thrilled to announce the commencement of training races on  class yachts! This exciting new activity is designed to offer both novice and experienced sailors the opportunity to hone their skills and experience the thrill of competitive sailing in one of Armenias most picturesque locations..", sender: "bot" },
        { text: "Here's a video tutorial for you: https://youtu.be/eDqfg_LexCQ?si=7wb35FM7zAU1gxEZ", sender: "bot" },
        { text: "Here's a PDF file for you to review:", sender: "user", file: { name: 'Document.pdf', url: '/path/to/Document.pdf', type: 'application/pdf' } },
        { text: "You can also check this Word document:", sender: "bot", file: { name: 'Report.docx', url: '/path/to/Report.docx', type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' } },
        { text: "Here's a Google Doc link for you to collaborate:", sender: "user", file: { name: 'Collaboration Document', url: 'https://docs.google.com/document/d/1K0gC1eS3v3LsR09q1HgG5Ir9df23sa-5P1rV0JsDO0w/edit', type: 'application/vnd.google-apps.document' } },
        { text: "Check out this Excel spreadsheet:", sender: "bot", file: { name: 'Data.xlsx', url: '/path/to/Data.xlsx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' } },
        { text: "Here's a PDF file for you to review:", sender: "user", file: { name: 'Data.pdf', url: '/path/to/Document.pdf', type: 'application/pdf' } },
        { text: "Here's a PowerPoint presentation you might find useful:", sender: "user", file: { name: 'Presentation.pptx', url: '/path/to/Presentation.pptx', type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' } },
        { text: "Also, take a look at this image:", sender: "bot", file: { name: 'Image.png', url: 'https://smart-connect-media.s3.amazonaws.com/media/cover_image/cover_image_bPDU2Vz.png', type: 'image/png' } },
        { text: "You might find this e-document interesting:", sender: "user", file: { name: 'E-Document.edoc', url: '/path/to/E-Document.edoc', type: 'application/octet-stream' } },
        { text: "Yes, I want to include user authentication and a task board.", sender: "user" },
    ];


    const { theme } = useTheme();

    const handleCopyText = (text) => {
        const textField = document.createElement("textarea");
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('Copy');
        textField.remove();
        toast.success("Text Copied");
    };
    const handleDownload = (file) => {
        // Initiate file download
        const link = document.createElement('a');
        link.href = file.url;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success(`${file.name} downloaded!`);
    };

    return (
        <div className="build-up-transcript-container">
            <div className="flex-grow p-4 space-y-2 build-up-transcript-chat">
                {messages.map((message, index) => {
                    const youtubeUrl = getYouTubeEmbedUrl(message.text);
                    return (
                        <>
                            <div key={index} className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"}`}>
                                <div className={`${message.sender === "user" ? "build-up-user-chat" : "build-up-bot-chat"} ${theme === "dark" ? "bg-[#2A2A2A] text-[#FFFFFF]" : "bg-[#FFFFFF]"}`}>
                                    {youtubeUrl ? (
                                        <div>
                                            <div style={{marginBottom:"10px"}}>
                                                Here's a link to the Yes Theory YouTube channel: <br/><a href="https://www.youtube.com/c/YesTheory" target="_blank" rel="noopener noreferrer" style={{color:"#F1F894"}}>Yes Theory - YouTube (Youtube)</a>.
                                            </div>
                                            <iframe
                                                width="427"
                                                height="240"
                                                src={youtubeUrl}
                                                frameBorder="0"
                                                allowFullScreen
                                                title="YouTube Video"
                                            ></iframe>
                                            <div style={{marginTop:"10px"}}>
                                                Additionally, you can watch one of their recent videos, "Living 5 DAYS in Complete Darkness," here: <a href="https://www.youtube.com/watch?v=VIDEO_ID" target="_blank" rel="noopener noreferrer">Living 5 DAYS in Complete Darkness - YouTube</a>.
                                            </div>
                                        </div>
                                    ) : message.file ? (
                                        <div className="flex items-center">
                                            {getFileTypeIcon(message.file.type)}
                                            <span className='ms-2'>{message.file.name}</span>
                                            <Button variant="ghost" size="icon" onClick={() => window.open(message.file.url, '_blank')}>
                                                <Download size={20} onClick={() => handleDownload(message.file)} />
                                            </Button>
                                        </div>
                                    )
                                        : (
                                            message.text
                                        )}
                                </div>
                            </div>
                            {message.sender === "bot" && (
                                <div className={`${theme === "dark" ? "build-up-icons-btn-dark" : "build-up-icons-btn-light"} flex space-x-2   gap-2`}>
                                    <Copy size={18} onClick={() => handleCopyText(message.text)} style={{ marginLeft: "5px" }} />
                                    <RotateCw size={18} />
                                    <img
                                        src={theme == "dark" ? nightShare : lightShare}
                                        style={{ height: "18px", width: "18px", cursor: "pointer" }} />
                                </div>

                            )}
                        </>
                    );
                })}
            </div>
            <InputBase />
            <Toaster position="top-center" />
        </div>
    );
};

export default Index;
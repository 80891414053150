import { useState } from 'react';
import darkLogo from '../../Assets/darkLogo.png';
import fireIcon from '../../Assets/fireIcon.png';
import lightLogo from '../../Assets/lightLogo.png';
import { useTheme } from 'next-themes';

import { Button } from "../../ui/button";
import UPGRADEButton from '../Common/UpgradBtn';
import MenuUpgradebtn from '../Common/MenuUpgradebtn';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../ui/select"
import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarSeparator,
    MenubarSub,
    MenubarSubContent,
    MenubarSubTrigger,
    MenubarTrigger,
} from '../../ui/menubar'
import { Card } from "../../ui/card"
import {
    SquareArrowOutUpRight,
    ChevronRight,
    EllipsisVertical,
    History,
    CopyPlus,
    Brush,
    Languages,
    Settings,
    ArrowLeftRight,
    Sun,
    CloudMoon,
    SquareArrowDown,
    Headset,
    LogOut,
    Flame,
    Zap,
    Info
} from 'lucide-react';
import './header.scss'

const Header = ({ setShowFloatingWindow, setShowMainScreen }) => {
    const { theme, setTheme } = useTheme();

    return (
        <>
            <div className="build-up-header-card" style={{
                backgroundColor: theme === "dark" ? "#191919" : "rgb(233 234 236)"
            }} >
                <Card className={`${theme == "dark" ? 'build-up-dark-header' : 'build-up-light-header'} build-up-header`}>
                    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "35px" }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: "16px" }} >
                                <img
                                    className="build-up-header-logo"
                                    src={theme === "dark" ? darkLogo : lightLogo}
                                    alt="Logo"
                                    onClick={() => setShowMainScreen(true)}
                                />
                                <UPGRADEButton />
                            </div>
                            <div className="build-up-button-group" style={{
                                color: theme === "dark" ? '#F1F894' : '#000000'
                            }}>
                                <Button variant="ghost" size="icon">
                                    <SquareArrowOutUpRight size={25} />
                                </Button>
                                <Menubar className="build-up-Menu-bar">
                                    <MenubarMenu>
                                        <MenubarTrigger><EllipsisVertical size={25} /></MenubarTrigger>
                                        <MenubarContent>
                                            <MenuUpgradebtn />
                                            <MenubarSeparator />
                                            <MenubarItem className="menubar-item-focused">
                                                <History /> History
                                            </MenubarItem>
                                            <MenubarItem className="menubar-item-focused">
                                                <CopyPlus /> Saved Prompts
                                            </MenubarItem>
                                            <MenubarSeparator />
                                            <MenubarSub className="menubar-item-focused">
                                                <MenubarSubTrigger className="menubar-item-focused">
                                                    <Brush /> Theme
                                                </MenubarSubTrigger>
                                                <MenubarSubContent >
                                                    <MenubarItem className="menubar-item-focused" onClick={() => setTheme('light')}>
                                                        <Sun /> Light
                                                    </MenubarItem>
                                                    <MenubarItem className="menubar-item-focused" onClick={() => setTheme('dark')}>
                                                        <CloudMoon /> Dark
                                                    </MenubarItem>
                                                    <MenubarItem className="menubar-item-focused" onClick={() => setTheme('system')}>
                                                        <SquareArrowDown /> System
                                                    </MenubarItem>
                                                </MenubarSubContent>
                                            </MenubarSub>
                                            <MenubarItem className="menubar-item-focused">
                                                <Languages /> Language
                                            </MenubarItem>
                                            <MenubarSeparator />
                                            <MenubarItem className="menubar-item-focused">
                                                <Settings /> Setting
                                            </MenubarItem>
                                            <MenubarItem className="menubar-item-focused">
                                                <ArrowLeftRight /> Change Shortcuts
                                            </MenubarItem>
                                            <MenubarSeparator />
                                            <MenubarItem className="menubar-item-focused">
                                                <Headset /> Support
                                            </MenubarItem>
                                            <MenubarItem className="menubar-item-focused">
                                                <LogOut /> Logout
                                            </MenubarItem>
                                        </MenubarContent>
                                    </MenubarMenu>
                                </Menubar>
                                <Button variant="ghost" size="icon" onClick={() => setShowFloatingWindow(false)}>
                                    <ChevronRight size={32} />
                                </Button>
                            </div>
                        </div>


                        <div className='build-up-header-selecters'>
                            <Select defaultValue="turbo" >
                                <SelectTrigger style={{ height: "36px", width: "255px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className="build-up-dropdown-selector">
                                    <SelectItem value="turbo" >
                                        <div className="flex gap-2 items-center">
                                            <img src={fireIcon} className="w-[21px] h-[18px]" alt="Fire Icon" />
                                            <span>Access Web: Turbo</span>
                                        </div>
                                    </SelectItem>
                                    <SelectItem value="off">
                                        <Info className="w-4 inline-block me-1 text-#ADD8E6-600 " />Off
                                    </SelectItem>
                                    <SelectItem value="regualar">
                                        <Zap className="w-4 inline-block me-1 text-yellow-600 " fill="#ffe808" strokeWidth={1} />
                                        Regualar Mode
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                            <Select defaultValue="ChatGpt">
                                <SelectTrigger style={{ height: "36px", width: "178px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent className="build-up-dropdown-selector">
                                    <SelectItem value="ChatGpt">
                                        <Zap className="w-4 inline-block me-1 text-[#7D68FF] " fill="#7D68FF" strokeWidth={0} />
                                        GPT 4mini
                                    </SelectItem>
                                    <SelectItem value="premium">Premium Access</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>

                </Card>

            </div>
        </>
    );
};


export default Header
import './footer.scss'
import { useTheme } from 'next-themes';
const Footer = () => {
    const {theme}=useTheme();
    return (
       <>
       </>
    )
}

export default Footer
import React, { useState } from 'react';
import { useTheme } from 'next-themes';

import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../../ui/card"
import { Button } from "../../ui/button"
import InputBase from '../Common/InputBase';
import nightCodeIcon from '../../Assets/nightCodeIcon.png';
import lightCodeIcon from '../../Assets/lightCodeIcon.png';

import './index.scss';
import { MessageSquareText, Globe, ImagePlus, Scan, FileText, Image, Send } from 'lucide-react';


const Index = ({ setShowMainScreen }) => {
    const { theme } = useTheme();

    const [file, setFile] = useState();
    const [image, setImage] = useState();

    return (
        <>
            <div className="build-up-main-container">
                <div className="flex-grow flex items-center p-2">
                    <div className="build-up-main-content grid grid-cols-2 gap-3 p-4 " >
                        <Card className="build-up-feature-button flex items-center justify-center p-4 "
                            onClick={() => {
                                setShowMainScreen(false)
                            }}
                        >
                            <div className="flex items-center" style={{ width: "182px", height: "58px", textAlign: "left", gap: "15px" }}>
                                <MessageSquareText size={32} />
                                <p style={{fontSize:"18px", width: "132px" }}>Chat with this Webpage</p>
                            </div>
                        </Card>
                        <Card className="build-up-feature-button flex items-center justify-center p-4 " onClick={() => {
                            setShowMainScreen(false)
                        }}>
                            <div className="flex items-center" style={{ width: "155px", height: "58px", textAlign: "left", gap: "15px" }}>
                                <Globe  size={32}  />
                                <p style={{fontSize:"18px", width: "132px" }}>Chatbots</p>
                            </div>
                        </Card>
                        <Card className="build-up-feature-button flex items-center justify-center p-4 " onClick={() => {
                            setShowMainScreen(false)
                        }}>
                            <div className="flex items-center" style={{ width: "182px", height: "58px", textAlign: "left", gap: "15px" }}>
                                <ImagePlus size={32}   />
                                <p style={{fontSize:"18px", width: "95px" }}>Generate Images</p>
                            </div>
                        </Card>
                        <Card className="build-up-feature-button flex items-center justify-center p-4 " onClick={() => {
                            setShowMainScreen(false)
                        }}>
                            <div className="flex items-center" style={{ width: "155px", height: "58px", textAlign: "left", gap: "15px" }}>
                                <img
                                    src={theme == "dark" ? nightCodeIcon : lightCodeIcon}
                                    size={32} 
                                     />
                                <p style={{fontSize:"18px", width: "95px" }}>Code Interpreter</p>
                            </div>
                        </Card>


                        <Card className="build-up-feature-button flex items-center justify-center space-x-2 col-span-2 text-center "
                            style={{ width: "502px", height: "85px" }}
                            onClick={() => {
                                setShowMainScreen(false)
                            }}>
                            <FileText  size={32}  />
                            <span style={{fontSize:"18px"}}>Chat with your Documents</span>
                        </Card>
                    </div>
                </div >
                <InputBase />
            </div >
        </>
    );
}

export default Index;

import './App.css';
import MainPage from './Components/MainPage';
import Transcripts from './Components/Transcripts/index'
import Layout from './Components/Layout';
import { ThemeProvider } from 'next-themes';
import { Button } from './ui/button';
import { useState } from 'react';
import Draggable from "react-draggable";

function App() {
  const [showFloatingWindow, setShowFloatingWindow] = useState(false);
  const [showMainScreen, setShowMainScreen] = useState(true);

  const handleClick = () => {
    setShowFloatingWindow(true);
  }

  return (
    <>
      <ThemeProvider attribute="class">
        {/* <Window
            id="build-up-floating-window"
            height={'100vh'}
            width={664}
            top={0}
            resizable={false}
            titleBar={{
              icon: "",
              title: "",
              buttons: { minimize: false, maximize: false, close: () => setShowFloatingWindow(false) },
            }}
          > */}
        <Layout
          showFloatingWindow={showFloatingWindow}
          setShowFloatingWindow={setShowFloatingWindow}
          setShowMainScreen={setShowMainScreen}
        >
          {showMainScreen ?
            <MainPage setShowMainScreen={setShowMainScreen} />
            :
            <Transcripts />
          }
        </Layout>
        {/* </Window> */}
      </ThemeProvider>

      <Draggable axis="y" >
        <Button className={`build-up-main-btn ${showFloatingWindow ? 'show': 'hide'}`} onClick={handleClick}>
          BU
        </Button>
      </Draggable>

    </>
  );
}

export default App;

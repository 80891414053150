import Header from './Header/Header';
import Footer from './Footer/Footer';
import { useTheme } from 'next-themes';
const Layout = ({ children, showFloatingWindow, setShowFloatingWindow, setShowMainScreen }) => {
  const { theme } = useTheme();

  return (
    <div
      className={`build-up-layout-container ${showFloatingWindow ? 'show' : 'hide'}`}
      style={{
        backgroundColor: theme === "dark" ? "#191919" : "#D8D8D8",
        height: "auto",
      }}
    >
      <Header setShowFloatingWindow={setShowFloatingWindow} setShowMainScreen={setShowMainScreen} />
      <main className={`${theme === "dark" ? "bg-[#191919]" : "bg-[#E9EAEC]"}`}>
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;

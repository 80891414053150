import { Button } from "../../ui/button";
import { Gem } from 'lucide-react';
import { useTheme } from 'next-themes';

const MenuUpgradebtn = () => {
    const {theme}=useTheme();
    return (
        <>
            <Button className="build-up-menu-upgrade-btn" style={{
                        color: theme === "dark" ? '#FFFFFF' : '#000000' 
                        }} >
                <Gem size={23} style={{marginRight:"3px"}} />UPGRADE PLAN
            </Button></>
    )
}

export default MenuUpgradebtn